import React from "react";
import "../css/CardExtra.css";
import { baseUrlBlog } from "./baseUrl";

import sinvideo from "../images/sinvideo.jpg";



export default function CardExtra(props) {

  let urlImgBlog = baseUrlBlog + `/${props.foto}`;
  let urlBlog = baseUrlBlog + `/${props.adjunto}`;


  return (
    <div className='containerExtraCard'>
       {props.foto.length > 0 ?



       <img
                      id="comments"
                      src={urlImgBlog}
                      alt={props.titulo}
                      name=""
                      id={props.id}
                    /> :   <img
                    id="comments"
                    src={sinvideo}
                    alt={props.titulo}
                    name=""
                    id={props.id}
                  /> }
  
        <div className='containerInfo'>
            <div className='cuadroPrincipal'>
                <h2>{props.titulo}</h2>
                <p>{props.copete}</p>
            </div>
            <div className='verMas'>

            {(props.link)?  <a href={props.link}>Ver Plan</a> : <a href={urlBlog}>Ver Plan</a>}
         
            </div>
        
        </div>
    </div>
  );
}

