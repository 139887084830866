import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppProvider";

import { baseUrl } from "./baseUrl";

export default function FetchPlanes(props) {
  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { planes, setPlanes } = useContext(AppContext);


  useEffect(() => {
   
      fetch(baseUrl + `/planes?t=${localStorage.getItem("token")}`)
        .then((response) => {
          return response.json();
        })

        .then((response) => {
          setPlanes(response.data);
        })
        
       .catch((error) => {
       
        });
    
  }, [setLoggedIn]);

  return null;
}
