import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppProvider";
import { baseUrl } from "./baseUrl";

import "../css/ModalComentarios.css";
import comments from "../images/commentsGrey.svg";
import basura from "../images/delete.svg";
import close from "../images/cruz.svg";
import fotoUsuario from "../images/fotoUsuario@2x.png";
import { baseUrlClientes } from "./baseUrl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function ModalComentarios(props) {
  const { showModal, setShowModal } = useContext(AppContext);
  const { datosUsuario } = useContext(AppContext);
  const { planes } = useContext(AppContext);
  const { planSeleccionado, setPlanSeleccionado } = useContext(AppContext);
  const { ejercicios, setEjercicios } = useContext(AppContext);
  const {
    ejercicioComentarioParams,
    setEjercicioComentarioParams,
  } = useContext(AppContext);
  const { comentarios, setComentarios } = useContext(AppContext);
  const [comentToSend, setComentToSend] = useState([]);
  const { comentarioEnviado, setComentarioEnviado } = useContext(AppContext);
  const { comentToDelete, setComentToDelete } = useContext(AppContext);
  // const [misComentarios, setMisComentarios] = useState([]);

  const basura = <FontAwesomeIcon icon={faTrashAlt} />;
  const close = <FontAwesomeIcon icon={faTimes} />;
  const menu = <FontAwesomeIcon icon={faEllipsisV} />;
  const urlImg = baseUrlClientes + `/${datosUsuario.foto}`;

  const className = showModal ? "modal-content" : "modal-hidden";
  const background = showModal ? "modal-background" : "";

  ///me traigo los comentarios
  useEffect(() => {
    if (ejercicioComentarioParams !== []) {
      fetch(
        baseUrl +
          `/comentarios?t=${localStorage.token}&plan=${planSeleccionado[1]}&ejercicio=${ejercicioComentarioParams[0]}`
      )
        .then((response) => {
          return response.json();
        })

        .then((response) => {
          if (response !== "") {
            setComentarios(response.data);
          }

          return response.data;
        })

        .then((response) => {
          response
            .filter((coment) => coment.leido_cliente === 0)
            .map((com) => {
              return fetch(baseUrl + "/marcacomoleido ", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Acept: "application/jsoon",
                },
                body: JSON.stringify({
                  t: localStorage.token,
                  idcomentario: com.idcomentario,
                }),
              }).then((response) => {
                return response.json();
              });
            });
        })

        .catch((error) => {});
    }
  }, [
    comentToDelete,
    setComentToDelete,
    setEjercicioComentarioParams,
    ejercicioComentarioParams,
    setComentarioEnviado,
    comentarioEnviado,
  ]);

  const deleteComentario = (e, id) => {
    setComentToDelete(!comentToDelete);

    fetch(baseUrl + "/borrarcomentario", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Acept: "application/jsoon",
      },

      body: JSON.stringify({
        t: localStorage.token,
        idcomentario: e.currentTarget.id,
      }),
    }).then((response) => {
      return response.json();
    });

   
  };

  //post de msi mensajes

  let handlerChange = (e) => {
    setComentToSend(e.target.value);
  };
  let handlerPublicar = (e) => {
    document.getElementById("comentario").value = "";
    e.preventDefault();

    fetch(baseUrl + "/nuevocomentario", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Acept: "application/jsoon",
      },
      body: JSON.stringify({
        t: localStorage.token,
        comentario: comentToSend,
        plan: planSeleccionado[1],
        ejercicio: ejercicioComentarioParams[0],
      }),
    })
      .then((response) => {
        return response.json();
      })

      .then((response) => {
        if (response !== "") {
          setComentToSend("");
          setComentarioEnviado(true);
        } else {
          alert("error al enviar mensaje");
        }
      })
      .then((response) => {
        setComentarioEnviado(false);
      });
  };

  function convertDateFormat(string) {
    var info = string.split("-").reverse().join("/");
    return info;
  }
  const style = {
    backgroundImage: `url(${urlImg})`,
    backgroundRepeat: "noRepeat",
    backgroundSize: "cover",
  };
  const style2 = {
    backgroundImage: `url(${fotoUsuario})`,
    backgroundRepeat: "noRepeat",
    backgroundSize: "cover",
  };

  return (
    <>
      {showModal ? (
        <div>
          <div className={background}>
            <div className="centered">
              <div className={className}>
                <div className="containerTopModal">
                  <div className="titleCerrar">
                    <h3>{ejercicioComentarioParams[1]}</h3>
                    <button onClick={() => setShowModal(!showModal)}>
                      {close}
                    </button>
                  </div>
                  <p>{ejercicioComentarioParams[2]}</p>
                  <div className="mensajesModal">
                    <img
                      id="comments"
                      src={comments}
                      alt="Logo"
                      name="dd"
                      id="123"
                    />
                    <a>{ejercicioComentarioParams[3]}</a>
                  </div>
                </div>
                <div className="containerBottomModalBig">
                  <div className="chatsContainer">
                    {comentarios.map((coment) => {
                      return (
                        <div className="chatContainer">
                          <div className="fotoYchat">
                            {coment.idcliente === null ? (
                              <circuloFoto
                                id="comments"
                                style={style2}
                                alt=""
                                id="user"
                              />
                            ) : (
                              <circuloFoto
                                id="comments"
                                style={style}
                                alt="Logo"
                                name="dd"
                                id="user"
                              />
                            )}

                            <div className="nombreYChat">
                              {coment.idcliente > 0 ? (
                                <h4>{datosUsuario.nombre}</h4>
                              ) : null}
                              <p>{coment.comentario}</p>
                            </div>
                          </div>
                          <div className="fechaYmenu">
                            <p>{convertDateFormat(coment.fecha)}</p>
                            {coment.idcliente !== null ? (
                              <a
                                id={coment.idcomentario}
                                onClick={(e, id) => deleteComentario(e, id)}
                              >
                                {basura}
                              </a>
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="containerBottomModal">
                    <textarea
                      type="text"
                      id="comentario"
                      name="comentario"
                      placeholder="Hacer un comentario"
                      onChange={handlerChange}
                    />

                    <button onClick={handlerPublicar}>publicar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
