import React, { useState } from "react";

export const AppContext = React.createContext();

export default function AppProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [token, setToken] = useState("");
  const [datosUsuario, setDatosUsuario] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [planSeleccionado, setPlanSeleccionado] = useState([]);
  const [ejercicios, setEjercicios] = useState([]);
  const [cargandoUsuario, setCargandoUsuario] = useState(false);
  const [ejercicioComentarioParams, setEjercicioComentarioParams] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [comentarioEnviado, setComentarioEnviado] = useState(false);
  const [comentToDelete, setComentToDelete] = useState(false);

  return (
    <AppContext.Provider
      value={{
        showModal,
        setShowModal,
        showPlans,
        setShowPlans,
        token,
        setToken,
        datosUsuario,
        setDatosUsuario,
        loggedIn,
        setLoggedIn,
        planes,
        setPlanes,
        planSeleccionado,setPlanSeleccionado,
        cargandoUsuario,
        setCargandoUsuario,
        ejercicios,
        setEjercicios,
        ejercicioComentarioParams,
        setEjercicioComentarioParams,
        comentarios,
        setComentarios,
        comentarioEnviado,
        setComentarioEnviado,
        blogs, setBlogs,
        comentToDelete, setComentToDelete
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
