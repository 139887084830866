import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppProvider";

import { baseUrl } from "./baseUrl";

export default function FetchPlanes() {
  const { ejercicios, setEjercicios } = useContext(AppContext);
  const { planSeleccionado, setPlanSeleccionado } = useContext(AppContext);
  const { comentarioEnviado, setComentarioEnviado } = useContext(AppContext);
  const {
    ejercicioComentarioParams,
    setEjercicioComentarioParams,
  } = useContext(AppContext);
  const { showModal, setShowModal } = useContext(AppContext);

  useEffect(() => {
    if (planSeleccionado !== []) {
      fetch(
        baseUrl +
          `/ejercicios?t=${localStorage.getItem("token")}&plan=${
            planSeleccionado[1]
          }`
      )
        .then((response) => {
          return response.json();
        })

        .then((response) => {
          if (response !== "") {
            setEjercicios(response.data);
        
          }
        })

        .catch((error) => {
        
        });
    }
  }, [
    planSeleccionado,
    setPlanSeleccionado,
    comentarioEnviado,
    setComentarioEnviado,
    ejercicioComentarioParams,
    setEjercicioComentarioParams,
    showModal,
    setShowModal,
  ]);

  return null;
}
