import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppProvider";

import { baseUrl } from "./baseUrl";

export default function FetchDatosUsuario() {
  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { datosUsuario, setDatosUsuario } = useContext(AppContext);
 
  

  useEffect(() => {
    if (localStorage.getItem !== "") {
      fetch(baseUrl + `/datos?t=${localStorage.token}`)
        .then((response) => {
          return response.json();
        })

        .then((response) => {
          if (response !== "") {
            setDatosUsuario(response.data);
           
          }
        })

        .catch((error) => {
        
        });
    }
  }, [loggedIn,setLoggedIn ]);

  return null;
}
