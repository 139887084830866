import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppProvider";
import "../css/CardVideos.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from "react-player";
import { baseUrlVideos } from "./baseUrl";

import comments from "../images/comments.svg";
import commentsGrey from "../images/commentsGrey.svg";

import sinvideo from "../images/sinvideo.jpg";

let iconoMensajes

export default function CardVideos(props) {
  const { showModal, setShowModal } = useContext(AppContext);
  const {
    ejercicioComentarioParams,
    setEjercicioComentarioParams,
  } = useContext(AppContext);

  const menu = <FontAwesomeIcon icon={faEllipsisV} />;
  iconoMensajes = props.noleidos > 0 ? comments : commentsGrey;
  
  useEffect(() => { 
   iconoMensajes = props.noleidos > 0 ? comments : commentsGrey;
 
  }
  ,[  ejercicioComentarioParams,
    setEjercicioComentarioParams,]);
  

  
  const handlerComents = (e, props) => {
   
    setShowModal(!showModal);
    let id = e.target.id;
    let nameEj = e.target.name;
    let des = e.target.alt;
    let cantidadMensajes = e.target.className;
   
    setEjercicioComentarioParams([id, nameEj, des, cantidadMensajes]);
    
  };
  const handlerTags = (tag) => {
    let array = tag.split(",");

    return array.map((tag) => {
      return <a>{tag}</a>;
    });
  };



  return (
    <div className='containerVideo'>
   {props.video.length > 0 ?
    <ReactPlayer
    id="reproductor"
    width="100%"
    height='100%'
    controls
    loop
    url={baseUrlVideos + `/${props.video}`}
    config={{ 
      file: { 
        attributes: {
          onContextMenu: e => e.preventDefault(),
          controlsList: 'nodownload'
        } 
      } 
    }}
 
  ></ReactPlayer>
   
   : <img
             
   src={sinvideo}
   name='s'
   id={props.ejNombre}
   alt={props.ejNombre}
   className='fotoNoVideo'
 />}
      
 
      <div className="footerImg">
        <div className="title">
          <h4>{props.ejNombre}</h4>
          <a>{menu}</a>
        </div>
        <p id="descripcionEjer">{props.des}</p>
        <div className='descripcionEjerContainer'>
        <div className="descripcionEjerIzq">
        <h6> repeticiones: <span>{props.rep}</span>.   </h6>
        <h6>tiempo: <span>{props.seg} seg</span>.</h6>
        </div>
        <div className="descripcionEjerDer">
        <h6> series: <span>{props.series}</span>.   </h6>
        <h6>bloques: <span>{props.bloques} </span>.</h6>
        </div>
        </div>
        <div className="footer">
          
          {props.tags?   <div className="clasificacion">
           
           {handlerTags(props.tags)}
           </div> : <div></div>}
        
          <div id="mensajes">
            <img
              onClick={(e, props) => handlerComents(e, props)}
              src={iconoMensajes}
              name={props.ejNombre}
              id={props.id}
              alt={props.des}
              className={props.comentarios}
            />

          
            <p>{props.comentarios}</p>
          
        </div>
      </div>
    </div>
    </div>
  );
}
