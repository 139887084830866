import React, { useState, useContext } from "react";
import { AppContext } from "../AppProvider";
import "../css/Login.css";

import FetchaDatosUsuario from "./FetchaDatosUsuario";
import FetchPlanes from "./FetchPlanes";
import FetchEjercicios from "./FetchEjercicios";
import FetchBlogs from "./FetchBlogs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import logo from "../images/sebastian-panotto-logo.svg";
import { baseUrl } from "./baseUrl";



export default function Login(props) {
  const ojo = <FontAwesomeIcon icon={faEye} />;
  const ojoCerrado = <FontAwesomeIcon icon={faEyeSlash} />;


  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { token, setToken } = useContext(AppContext);
  const [form, setForm] = useState({
    usuario: "",
    contra: "",
  });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { planes, setPlanes } = useContext(AppContext);

  const [eye, setEye] = useState(true);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const iniciarSesion = () => {
    fetch(baseUrl + `/login?usuario=${form.usuario}&contra=${form.contra}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.data !== "") {
          setLoggedIn(true);
          setToken(response.data);
          localStorage.setItem("token", response.data);
      
        } else {
          setError(true);
          setErrorMsg("usuario o contrasena incorrectos");
          localStorage.setItem("token", undefined);


        }
        
      })

      .then((response) => {
      
        if (localStorage.token === undefined) {
          setError(true);
          setErrorMsg("usuario o contrasena incorrectos");
        }
      
        fetch(baseUrl + `/planes?t=${localStorage.getItem("token")}`)
          .then((response) => {
            return response.json();
          })

          .then((response) => {
            setPlanes(response.data);
          })
          .then((response) => {
        
            if (localStorage.token !== undefined) {
              props.history.push("/menu");
            }
          })

          .catch((error) => {
            console.log(error);
          });
      })
      .then((response) => {
        <FetchEjercicios />;
      })

      .catch((error) => {
       
        setError(true)
        setErrorMsg("Error de conexion");
      });
  };
  
  const eyeClass = eye ? "password" : "text";
  
  const handlerEye = (e) => {
    setEye(!eye);
  };


  return (
    <div className="principal">
      <div className="completo">
        <div className="izquirda">
          <div className="contenedorIzq">
            <p>
              Comenzar mi <br />
              entrenamiento
            </p>

            <form>
              <div className="usuarioDiv usuarDiv">
               
                <input
                  type="text"
                  name="usuario"
                  placeholder="Usuario"
                  onChange={handleChanges}
                />
              </div>
              <div className="usuarioDiv contrasenaDiv">
              
                <label > <input
                className={eyeClass}
                  type={eyeClass}
                  name="contra"
                  placeholder='Contrase&ntilde;a'
                  onChange={handleChanges}
                />  {eye? <span onClick={handlerEye}>{ojo}</span>:<span onClick={handlerEye}>{ojoCerrado}</span>} </label>
               
              </div>
            </form>
            <button onClick={iniciarSesion}>Ingresar</button>
          </div>
          {error ? (
            <div className="alertError">
              <p>{errorMsg}</p>
            </div>
          ) : null}
        </div>
        <div className="derecha">
          <img id="LogoLogin " src={logo} alt="Logo" />
        </div>
      </div>
      <FetchaDatosUsuario />
      <FetchPlanes />
      <FetchEjercicios />
      <FetchBlogs/>
    </div>
  );
}
