import React from 'react'
import Login from "../components/Login"
import Main from "../components/Main"


const routes= [
    {path :'/',
component: Login},
{ path :'/menu',
component: Main},

]
export default routes