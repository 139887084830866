import React, { useState, useContext } from "react";
import { AppContext } from "../AppProvider";
import "../css/CardUsuario.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-regular-svg-icons";
import { baseUrlClientes } from "./baseUrl";

export default function CardUsuario() {
  const { datosUsuario } = useContext(AppContext);

  const urlImg = baseUrlClientes + `/${datosUsuario.foto}`;
  const userNoImg = <FontAwesomeIcon icon={faUser} />;

  const style = {
    backgroundImage: `url(${urlImg})`,
    backgroundRepeat: "noRepeat",
    backgroundSize: "cover",
  };

  return (
    <div className="containerUsuario">
      <div className="containerTop">
        {datosUsuario.foto ? (
          <fotoUsuario style={style}></fotoUsuario>
        ) : (
          <fotoUsuario>{userNoImg} </fotoUsuario>
        )}
      </div>
      <div className="containerBottom">
        <div className="nombreEntrenamiento">
          <h3>{datosUsuario.nombre} </h3>
          <h6>{datosUsuario.tipo}</h6>
        </div>
        <div className="fichaPersonal">
          <h4> Ficha Personal</h4>
          <p>
            Edad <span>{datosUsuario.edad}</span>
          </p>
          <p>
            Altura <span>{datosUsuario.altura}mts</span>
          </p>
          <p>
            Peso <span>{datosUsuario.peso}kg</span>
          </p>
          <p>
            Sexo<span>{datosUsuario.sexo === 'Ma'? 'Masculino': 'Femenino'}</span>
          </p>
          <h4> Observaciones</h4>
          <p id="ultimoTexto">{datosUsuario.observaciones}</p>
        </div>
      </div>
    </div>
  );
}
