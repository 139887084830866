import React, {  useContext, useEffect } from "react";
import { AppContext } from "../AppProvider";

import { baseUrl } from "./baseUrl";

export default function FetchDatosUsuario() {
  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { blogs, setBlogs } = useContext(AppContext);

  useEffect(() => {
    if (localStorage.getItem !== "") {
      fetch(baseUrl + `/blog?t=${localStorage.getItem("token")}`)
        .then((response) => {
          return response.json();
        })

        .then((response) => {
          if (response !== "") {
        
            setBlogs(response.data);
          }
        })

        .catch((error) => {
         
        });
    }
  }, [loggedIn, setLoggedIn]);

  return null;
}
