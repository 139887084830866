import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppProvider";

import ModalComentarios from "./ModalComentarios";
import FetchaDatosUsuario from "./FetchaDatosUsuario";
import FetchPlanes from "./FetchPlanes";
import FetchEjercicios from "./FetchEjercicios";
import FetchBlogs from "./FetchBlogs";

import "../css/Main.css";
import { baseUrlClientes } from "./baseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import logo from "../images/sebastian-panotto-logo.svg";

import CardUsuario from "./CardUsuario";
import CardExtra from "./CardExtra";
import Dia from "./Dia";

export default function Main(props) {
  const { datosUsuario } = useContext(AppContext);
  const { planes } = useContext(AppContext);
  const { planSeleccionado, setPlanSeleccionado } = useContext(AppContext);
  const { showPlans, setShowPlans } = useContext(AppContext);
  const { blogs } = useContext(AppContext);
  const [showMenuUsuario, setShowMenuUsuario] = useState(false);

  const userNoImg = <FontAwesomeIcon icon={faUserCircle} />;
  const flecha = <FontAwesomeIcon icon={faCaretDown} />;
  const urlImg = baseUrlClientes + `/${datosUsuario.foto}`;

  useEffect(() => {
    if (planes.length > 0) {
      setPlanSeleccionado([
        planes[0].nombre,
        planes[0].idplan,
        planes[0].descripcion,
      ]);
    }
  }, [planes]);

  const handlerPlans = (e) => {
    setShowPlans(!showPlans);
  };

  const seleccionarPlan = (e, des) => {
    setPlanSeleccionado([e.target.name, e.target.id, des]);
    setShowPlans(false);
  };
  const handlerButtonUsuario = (e) => {
    setShowMenuUsuario(!showMenuUsuario);
  };
  const handlerCerrarSesion = (e) => {
    localStorage.removeItem("token");
    props.history.push("/");
  };

  const className = showPlans ? "seleccionPlan" : "modal-hidden";
  const classNameButton = showPlans ? "up" : "";

  const mostarMenuUsuario = showMenuUsuario ? "" : "hidenMenu";

  let planParaMostar = () => {
    if (planSeleccionado.length > 0) {
      return planSeleccionado[0];
    } else if (planes.length > 0) {
      return planes[0].nombre;
    } else {
      return "";
    }
  };
  const style = {
    backgroundImage: `url(${urlImg})`,
    backgroundRepeat: "noRepeat",
    backgroundSize: "cover",
  };



  return (
    <div>
      <FetchaDatosUsuario />
      <FetchPlanes />
      <FetchEjercicios />
      <FetchBlogs />

      <header className="header">
        <logo>
          {" "}
          <img id="imagenLogo " src={logo} alt="Logo" />
        </logo>
        <cuadroDerecha className="cuadroDerecha">
          <redes>
            <a href='https://www.facebook.com/spanotto' target="_blank"><img id="imagenVideo " src={facebook} alt="Logo" /></a>
            <a href='https://www.instagram.com/sebastian_fitness_coach/' target="_blank"><img id="imagenVideo " src={instagram} alt="Logo" /></a>
          </redes>
          <usuario className="usuarioContainer">
            <div className="usuarioMenu">
              <p>{datosUsuario.nombre}</p>
              <a onClick={handlerButtonUsuario}>{flecha}</a>
            </div>
            <div className="usuarioMenuOpen" id={mostarMenuUsuario}>
              <h6 onClick={handlerCerrarSesion}>Cerrar sesion </h6>
            </div>
          </usuario>
          {datosUsuario.foto ? (
            <circulo style={style}></circulo>
          ) : (
            <fotoUsuario>{userNoImg} </fotoUsuario>
          )}
        </cuadroDerecha>
      </header>
      <body className="body">
        <div className="body2">
          <div className="divIzq">
            <CardUsuario />
            {blogs.map((blog) => {
              return (
                <CardExtra
                  titulo={blog.titulo}
                  copete={blog.copete}
                  fecha={blog.fecha}
                  foto={blog.foto}
                  adjunto={blog.adjunto}
                  link={blog.link}
                  id={blog.idblog}
                />
              );
            })}

            {/* /////seccion DERECHA */}
          </div>
          <div className="divDer">
            <div className="slectPlanContainer">
              <div className="plan">
                <div className="planIzq">
                  <h5>{planParaMostar()}</h5>
                </div>
                <button
                  className="selectPlan  "
                  id={classNameButton}
                  onClick={handlerPlans}
                >
                  {" "}
                  <span   name="1">{flecha}</span>
                </button>
              </div>
              <div className={className} id="seleccionPlan">
                {planes.map((p, i) => {
                  return (
                    <a
                    key={p.idplan}
                      id={p.idplan}
                      onClick={(e) => seleccionarPlan(e, p.descripcion)}
                      name={p.nombre}
                      className="otrosPlanes"
                    >
                      {p.nombre}
                    </a>
                  );
                })}
              </div>
            </div>

            {planSeleccionado === [] ? null : (
              <p id="p">{planSeleccionado[2]}</p>
            )}

            <Dia />
          </div>
        </div>
      </body>
      <ModalComentarios />
    </div>
  );
}
