import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../AppProvider";
import "../css/Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUserCircle } from "@fortawesome/free-solid-svg-icons";

import CardVideos from "./CardVideos";

export default function Dia(key, dia) {
  const [showDay, setShowDay] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [showCategory, setShowCategory] = useState(false);
  const { ejercicios, setEjercicios } = useContext(AppContext);

  const flecha = <FontAwesomeIcon icon={faCaretDown} />;

  useEffect(() => {
    setEjercicios(
      ejercicios.map((item, index) => {
        return { ...item, mostrar: showDay[index] };
      })
    );
  }, [showDay, setShowDay]);

  const handlerShowDays = (e) => {
    setShowDay({
      ...showDay,
      [e.currentTarget.name - 1]: !showDay[e.currentTarget.name - 1],
    });
  };

  const handlerShowExercise = (e, name, className) => {
    setShowCategory(!showCategory);

    ejercicios.map((ej) => {
      ej.ejerciciosxcategorias.map((cat) => {
        if (cat.categoria.diacategoria === e.currentTarget.name) {
          setEjercicios(
            ejercicios,
            (cat.categoria.visible = !cat.categoria.visible)
          );
        }
      });
    });
  };

  return (
    <div>
      {ejercicios === undefined ? (
        <p>...</p>
      ) : (
        ejercicios.map((ejer, index) => {
          return (
            <div id={ejer.dia}>
              <div className="dias">
                <h3>
                  Dia {ejer.dia} |{" "}
                  {ejer.ejerciciosxcategorias.map((categ) => {
                    return <span> {categ.categoria.nombre} </span>;
                  })}
                </h3>
                <a
                  className={ejer.mostrar === true ? "" : `nada${ejer.dia}`}
                  name={ejer.dia}
                  id={ejer.mostrar}
                  value={ejer.dia}
                  onClick={(e) => handlerShowDays(e)}
                >
                  {flecha}
                </a>
              </div>
              {ejer.ejerciciosxcategorias.map((categ, i) => {
                return (
                  <div
                    className="submenuDiasContainer"
                    id={
                      ejer.mostrar === true ? `hiden${ejer.dia}` : ejer.mostrar
                    }
                  >
                    <div className="submenuDias">
                      <h3>{categ.categoria.nombre}</h3>

                      <a
                        name={categ.categoria.diacategoria}
                        className={
                          categ.categoria.visible === false
                            ? "flechaAbajo"
                            : "flechaDerecha"
                        }
                        onClick={(e, name, className) =>
                          handlerShowExercise(e, name, className)
                        }
                      >
                      
                        {flecha}
                      </a>
                    </div>
                   
                    {categ.categoria.visible === false ? null : (
                      <div>
                        <p>{categ.categoria.descripcion}</p>

                        <div className="VideosContainer">
                          <div className="row">
                            {categ.ejercicios.map((ej) => {
                              return (
                                <div className="col">
                                  <CardVideos
                                    ejNombre={ej.ejercicionombre}
                                    id={ej.idejercicio}
                                    rep={ej.repeticiones}
                                    seg={ej.segundos}
                                    des={ej.descripcionejercicio}
                                    series={ej.series}
                                    bloques={ej.bloques}
                                    tags={ej.tags}
                                    comentarios={ej.comentarios}
                                    noleidos={ej.noleidos}
                                    video={ej.video}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
}
