import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import routes from "./routes/Routes";
import AppProvider from "./AppProvider";

function App() {
  return (
    <AppProvider>
      <Router>
        <Switch>
          {routes.map((route) => (
            <Route
              Key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
